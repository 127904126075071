import type React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Hero from "./components/Hero";
import LoggedOutPage from "./components/LoggedOutPage";
import TestPresentation from "./components/TestPresentation";
import UserProgress from "./components/UserProgress";
import { useAuth } from "./contexts/AuthContext";
import useChallenges from "./hooks/useChallenges";
import useTests from "./hooks/useTests";
import type { ChallengeData } from "./types";

const GrindOlympiadsIndex: React.FC = () => {
  const [showTests, setShowTests] = useState<boolean>(true);
  const { currentUser, userProfile, isAdminMode } = useAuth();
  const navigate = useNavigate();
  const {
    tests,
    loading: testsLoading,
    error: testsError,
    userProgress,
  } = useTests();
  const {
    challenges,
    loading: challengesLoading,
    error: challengesError,
  } = useChallenges();

  if (!currentUser) {
    return <LoggedOutPage />;
  }

  if (testsLoading || challengesLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );
  }

  if (testsError || challengesError) {
    return (
      <div
        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
        role="alert"
      >
        <strong className="font-bold">Error: </strong>
        <span className="block sm:inline">{testsError || challengesError}</span>
      </div>
    );
  }

  const userName =
    userProfile?.name || currentUser.displayName || currentUser.email || "User";

  const handleCreateNewTest = () => {
    navigate("/exam/new");
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Hero
        showTests={showTests}
        setShowTests={setShowTests}
        data-testid="hero"
      />
      <div className="container mx-auto py-8">
        <h2 className="text-2xl font-bold mb-4">Welcome, {userName}!</h2>

        <div className="flex justify-between items-center mb-6">
          {isAdminMode && (
            <button
              onClick={handleCreateNewTest}
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
              type="button"
            >
              Create New Test
            </button>
          )}
        </div>
      </div>
      {showTests && (
        <TestPresentation
          tests={tests}
          isAdminMode={isAdminMode}
          userProgress={userProgress}
          challenges={challenges || []}
        />
      )}
      <UserProgress
        userProgress={userProfile?.progress || []}
        data-testid="user-progress"
      />
    </div>
  );
};

export default GrindOlympiadsIndex;
