import { getFunctions, httpsCallable } from "firebase/functions";
import { useCallback, useEffect, useState } from "react";
import { getIdToken } from "../firebase";
import type { Challenge, ChallengeData } from "../types";

const useChallenges = () => {
  const [challenges, setChallenges] = useState<ChallengeData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const functions = getFunctions();

  const fetchChallenges = useCallback(async () => {
    try {
      setLoading(true);
      const idToken = await getIdToken();
      const fetchChallengesFunction = httpsCallable<
        { idToken: string },
        { challenges: ChallengeData[] }
      >(functions, "fetchChallenges");

      const result = await fetchChallengesFunction({ idToken });
      setChallenges(result.data.challenges);
      setError(null);
    } catch (err) {
      console.error("Error fetching challenges:", err);
      setError("Failed to load challenges. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [functions]);

  useEffect(() => {
    fetchChallenges();
  }, [fetchChallenges]);

  const submitChallengeResponse = async (
    challengeId: string,
    responses: { [key: string]: { answer: string; timeSpent: number } },
  ) => {
    try {
      const idToken = await getIdToken();
      const submitChallengeResponseFunction = httpsCallable<
        {
          idToken: string;
          challengeId: string;
          responses: { [key: string]: { answer: string; timeSpent: number } };
        },
        { score: number }
      >(functions, "submitChallengeResponse");

      const result = await submitChallengeResponseFunction({
        idToken,
        challengeId,
        responses,
      });

      // Update the local state with the new progress
      setChallenges((prevChallenges) =>
        prevChallenges.map((challenge) =>
          challenge.id === challengeId
            ? {
                ...challenge,
                userProgress: {
                  completedAt: new Date().toISOString(),
                  score: result.data.score,
                  problemResponses: Object.fromEntries(
                    Object.entries(responses).map(
                      ([problemNumber, response]) => [
                        problemNumber,
                        {
                          answer: response.answer,
                          correct:
                            result.data.score >
                            (challenge.userProgress?.score ?? 0),
                          timeSpent: response.timeSpent,
                        },
                      ],
                    ),
                  ),
                },
              }
            : challenge,
        ),
      );

      return result.data;
    } catch (err) {
      console.error("Error submitting challenge response:", err);
      throw err;
    }
  };

  return {
    challenges,
    loading,
    error,
    fetchChallenges,
    submitChallengeResponse,
  };
};

export default useChallenges;
