import { ChevronDown, ChevronUp, Edit, HardHat, Star } from "lucide-react";
import type React from "react";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import type {
  ChallengeData,
  Exam,
  TestPresentationProps,
  TestProgress,
} from "../types";

interface ProgressTokenProps {
  challengeId: string | null;
  status: number;
  onClick: () => void;
}

const ProgressToken: React.FC<ProgressTokenProps> = ({
  challengeId,
  status,
  onClick,
}) => {
  const colors = ["#F0F0F0", "#FFD700", "#CD7F32"]; // light gray, gold, bronze

  return (
    <button
      type="button"
      className={`w-6 h-6 flex justify-center items-center rounded-full cursor-pointer focus:outline-none focus:ring-1 focus:ring-blue-500 transition-colors ${
        status === -1 ? "border-2 border-dashed border-gray-300" : ""
      }`}
      style={{
        backgroundColor:
          challengeId === null
            ? "#FFF3CD"
            : status === -1
              ? "transparent"
              : colors[status],
      }}
      onClick={onClick}
      disabled={challengeId === null}
      aria-label={
        challengeId === null
          ? "Challenge not available"
          : status === -1
            ? "No badge earned"
            : `Badge status ${status}`
      }
    >
      {challengeId === null ? (
        <HardHat size={12} className="text-amber-500" />
      ) : (
        status !== -1 && <Star size={12} className="text-white" />
      )}
    </button>
  );
};

interface ExamProgressProps {
  exam: Exam;
  progress: TestProgress | undefined;
  isAdminMode: boolean;
  challenges: ChallengeData[];
}

const ExamProgress: React.FC<ExamProgressProps> = ({
  exam,
  progress,
  isAdminMode,
  challenges,
}) => {
  const navigate = useNavigate();

  const handleChallengeClick = (challengeId: string) => {
    navigate(`/exam/${exam.id}`, { state: { challengeId } });
  };

  const progressRanges = ["1-10", "1-10 (B)", "11-15", "16-20", "21-25"];
  const challengeTypes = [
    "firstTen",
    "firstTen (B)",
    "11-15",
    "16-20",
    "21-25",
  ];

  return (
    <div className="flex items-center p-2 border-b border-gray-200 hover:bg-gray-50">
      <div className="flex space-x-2">
        {progressRanges.map((range, index) => {
          const expectedType = challengeTypes[index];
          const challenge = challenges.find(
            (c) =>
              c.type === challengeTypes[index] && c.examIds?.includes(exam.id),
          );

          return (
            <ProgressToken
              key={range}
              challengeId={challenge?.id || null}
              status={progress ? progress[range] : -1}
              onClick={() => challenge && handleChallengeClick(challenge.id)}
            />
          );
        })}
      </div>
      <div className="flex-grow ml-4">
        <div className="text-sm font-medium text-gray-800">{exam.name}</div>
        <div className="text-xs text-gray-500">{exam.year}</div>
      </div>
      {isAdminMode && (
        <button
          type="button"
          onClick={() => navigate(`/exam/${exam.id}`)}
          className="ml-4 bg-blue-500 text-white px-2 py-1 rounded text-xs hover:bg-blue-600 transition-colors flex items-center"
          aria-label={`Edit ${exam.name}`}
        >
          <Edit size={12} className="mr-1" />
          Edit
        </button>
      )}
    </div>
  );
};

interface ExamTypeSectionProps {
  type: string;
  exams: Exam[];
  progress: Record<string, TestProgress>;
  isAdminMode: boolean;
  challenges: ChallengeData[];
}

const ExamTypeSection: React.FC<ExamTypeSectionProps> = ({
  type,
  exams,
  progress,
  isAdminMode,
  challenges,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => setIsExpanded((prev) => !prev);

  return (
    <div className="mb-3">
      <button
        type="button"
        onClick={toggleExpand}
        className="flex items-center justify-between w-full p-2 bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        aria-expanded={isExpanded}
        aria-controls={`section-${type}`}
      >
        <span className="font-semibold text-sm">{type}</span>
        {isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
      </button>
      {isExpanded && (
        <div id={`section-${type}`} className="mt-2">
          {exams
            .sort((a, b) => Number(a.year) - Number(b.year))
            .map((exam) => (
              <ExamProgress
                key={exam.id}
                exam={exam}
                progress={progress[exam.id]}
                isAdminMode={isAdminMode}
                challenges={challenges.filter((c) =>
                  c.examIds?.includes(exam.id),
                )}
              />
            ))}
        </div>
      )}
    </div>
  );
};

const TestPresentation: React.FC<TestPresentationProps> = ({
  tests,
  isAdminMode,
  userProgress,
  challenges,
}) => {
  const [showAMC, setShowAMC] = useState(true);
  const navigate = useNavigate();

  const groupedExams = useMemo(() => {
    const grouped: Record<string, Exam[]> = {};

    for (const exam of tests) {
      const groupName = exam.examType;
      if (!grouped[groupName]) {
        grouped[groupName] = [];
      }
      grouped[groupName].push(exam);
    }

    return grouped;
  }, [tests]);

  const amcGroupOrder = ["AMC-8", "AMC-10", "AMC-12"];
  const orderedAMCGroups = amcGroupOrder
    .filter((type) => groupedExams[type])
    .map((type) => ({
      type,
      exams: groupedExams[type],
    }));

  const nonAMCTests = tests.filter(
    (test) => !amcGroupOrder.includes(test.examType),
  );

  const toggleView = () => {
    setShowAMC((prev) => !prev);
  };

  return (
    <div className="p-4 bg-gray-50">
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-md p-4">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl font-bold">Test Progress Dashboard</h1>
          <button
            type="button"
            onClick={toggleView}
            className="bg-blue-500 text-white px-3 py-1 rounded text-sm hover:bg-blue-600 transition-colors"
          >
            {showAMC ? "Switch to Non-AMC View" : "Switch to AMC View"}
          </button>
        </div>
        {showAMC && (
          <div className="flex items-center px-2 py-1 bg-gray-200 rounded-md mb-2">
            <div className="flex space-x-2 w-40">
              <span className="text-xs font-medium text-gray-700">
                First Ten (A)
              </span>
              <span className="text-xs font-medium text-gray-700">
                First Ten (B)
              </span>
              <span className="text-xs font-medium text-gray-700">11-15</span>
              <span className="text-xs font-medium text-gray-700">16-20</span>
              <span className="text-xs font-medium text-gray-700">21-25</span>
            </div>
            <div className="flex-grow ml-4">
              <span className="text-xs font-medium text-gray-700">
                Exam Details
              </span>
            </div>
            {isAdminMode && (
              <div className="ml-4">
                <span className="text-xs font-medium text-gray-700">
                  Actions
                </span>
              </div>
            )}
          </div>
        )}
        {showAMC ? (
          <div className="h-96 overflow-y-auto">
            {orderedAMCGroups.map(({ type, exams }) => (
              <ExamTypeSection
                key={type}
                type={type}
                exams={exams}
                progress={userProgress}
                isAdminMode={isAdminMode}
                challenges={challenges}
              />
            ))}
          </div>
        ) : (
          <>
            <div className="flex items-center px-2 py-1 bg-gray-200 rounded-md mb-2">
              <span className="text-xs font-medium text-gray-700">
                Exam Details
              </span>
              {isAdminMode && (
                <span className="ml-4 text-xs font-medium text-gray-700">
                  Actions
                </span>
              )}
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
              {nonAMCTests
                .sort((a, b) => Number(a.year) - Number(b.year))
                .map((test) => (
                  <div
                    key={test.id}
                    className="bg-white rounded-md shadow-sm p-3 flex flex-col justify-between border hover:bg-gray-50 transition-colors"
                  >
                    <div>
                      <h3 className="font-semibold text-sm">
                        {test.competition}
                      </h3>
                      <p className="text-gray-500 text-xs">{`${test.year} - ${test.name}`}</p>
                    </div>
                    <button
                      type="button"
                      onClick={() => navigate(`/exam/${test.id}`)}
                      className="mt-2 bg-blue-500 text-white py-1 px-2 rounded text-xs hover:bg-blue-600 transition-colors"
                      aria-label={
                        isAdminMode ? `Edit ${test.name}` : `Take ${test.name}`
                      }
                    >
                      {isAdminMode ? "Edit Test" : "Take Test"}
                    </button>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TestPresentation;
